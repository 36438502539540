// gets posts using Post ID
export const getGift = async (giftID) => {
    const origin = 'https://us-central1-mana-api-prod.cloudfunctions.net';
    const url = `${origin}/api/gifts/${giftID}`;
    try {
      return await fetch(url, {
          headers: {
              'Content-Type': 'application/json',
              'X-MARCO': 'POLO'
            },
      })
      .then(res => res.json())
      .then(payload => {
          console.log(payload)
          return payload
      });
    } catch (error) {
      // TODO: implement error handler
      console.log(error)
    }
    return null;
  }

// // gets posts using Post ID
// export const markGiftAsOpened = async (giftID) => {
//   const origin = 'https://us-central1-mana-api-prod.cloudfunctions.net';
//   const url = `${origin}/api/gifts/${giftID}/opened`;
//   try {
//      await fetch(url, {
//       method: 'POST'
//     })
//   } catch (error) {
//     console.log(error)
//   }
// }
