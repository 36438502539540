import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import styled, { keyframes } from 'styled-components';
import wordmark from './mana-wordmark.svg';

/* wrap the entire page */
const LandingPageWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
`;
LandingPageWrapper.displayName = 'LandingPageWrapper';

/* contains wordmark at the top of the page */
const PageHeader = styled.header`
  height: 9vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
`;
PageHeader.displayName = 'PageHeader';

/* Mana wordmark in upper left corner */
const Wordmark = styled.img`
  position: relative;
  top: 0;
  left: 2.6rem;
  height: 3rem;
  width: 6rem;
  min-height: 3rem;
  min-width: 6rem;
`;
Wordmark.displayName = 'Wordmark';

/* contains logo */
const LandingPageBody = styled.header`
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`;
LandingPageBody.displayName = 'LandingPageBody';

/* keyframes rotation of logo */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

/* circular, rotating logo of W */
const Logo = styled.img`
  height: 50vmin;
`;
Logo.displayName = 'Logo';

class LandingPage extends Component {
  render() {
    const cardImages = [
      'https://cdn.dribbble.com/users/1044993/screenshots/9410291/media/6324168df4ecce3c054ac151d0d6503e.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/3381849/screenshots/14476910/media/7a5d3450639893a643f22828ac7ce66e.jpg?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/1044993/screenshots/12390940/media/02a0cc2902be63b99498a377f4fe298c.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/1044993/screenshots/14680397/media/2f73343ab935b56c7c35ea2f3b39b31b.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/1044993/screenshots/14320957/media/d1cf37b04e4c199d1f4ed66c4ce2e659.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/1044993/screenshots/15160588/media/0d13882ecd292099d38a7cf4883b423d.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/1044993/screenshots/13980242/media/c54095bb9fa26bd462f62f4456f14730.png?compress=1&resize=1600x1200',
      'https://cdn.dribbble.com/users/2123888/screenshots/15586419/media/4cedd5904a1d20efd62c62d0c79f5245.jpg?compress=1&resize=1600x1200'
    ]
    const meta = {
      title: 'You\'ve Been Gifted 🎉',
      description: 'Tap to open gift...',
      image: cardImages[Math.floor(Math.random()*cardImages.length)],
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,meta,document,html,tags'
        }
      }
    };

    return (
        <DocumentMeta {...meta}>
          <LandingPageWrapper>
            <PageHeader>
            </PageHeader>
            <LandingPageBody>
              <Logo src={wordmark} alt="" />
            </LandingPageBody>
          </LandingPageWrapper>
        </DocumentMeta>
    );
  }
}

export default LandingPage;
