import React, { Component } from 'react';
import Moment from 'react-moment';
import Lottie from 'react-lottie';
import styled, { keyframes } from 'styled-components';
import { getGift } from './firebase/firebase-api';
import wordmark from './mana-wordmark.svg';
import calendarIcon from './calendar-icon.png';
import sparklesIcon from './sparkles-icon.png';

import * as animationData from './card.json';

const defaultOptions = {
  loop: false,
  renderer: 'svg',
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

/* wrap the entire page */
const PageWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  background-color: #01579B;
`;
PageWrapper.displayName = 'PageWrapper';

/* wrap the entire page */
const LoadingPageWrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  background-color: #FFFFFF;
`;
LoadingPageWrapper.displayName = 'LoadingPageWrapper';

/* contains share post header */
const PageHeader = styled.header`
  border-bottom: 1px solid #E8E8E8;
  height: 10vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #01579B;
  z-index: 1;
  @media(max-width: 28.25rem) {
    height: 8vh;
  }
`;
PageHeader.displayName = 'PageHeader';

/* Mana wordmark in upper left corner */
const Wordmark = styled.img`
  position: relative;
  top: 0;
  left: 2.6rem;
  height: 4rem;
  width: 4rem;
  min-height: 3rem;
  min-width: 6rem;
  @media(max-width: 31.25rem) {
    left: 1.6rem;
  }
  @media(max-width: 28.25rem) {
    left: 1rem;
  }
`;
Wordmark.displayName = 'Wordmark';

/* Mana download button in upper left corner */
const DownloadImage = styled.img`
  height: 8vh;
  margin-top: 3vh;
`;
DownloadImage.displayName = 'DownloadImage';

/* contains everything below header */
const DownloadContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-flow: row nowrap;
  align-items: center;
  background-color: #F7F7F7;
  justify-content: center;
  @media(max-width: 28.25rem) {
    flex-flow: column;
  }
`;
DownloadContainer.displayName = 'DownloadContainer';

/* contains everything below header */
const BodyContainer = styled.div`
  padding-top: .5rem;
  min-height: 80vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #01579B;
  @media(max-width: 28.25rem) {
    margin-top: 1rem;
    justify-content: flex-start;
    align-items: center;
  }
`;
BodyContainer.displayName = 'BodyContainer';

/* contains everything below header */
const LoadingBodyContainer = styled.div`
  margin-top: 4.5rem;
  padding-top: .5rem;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  @media(max-width: 28.25rem) {
    margin-top: 3rem;
    justify-content: flex-start;
  }
`;
LoadingBodyContainer.displayName = 'LoadingBodyContainer';

/* keyframes rotation of logo */
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

/* contains loading spinner */
const LoadingContainer = styled.div`
  min-height: 91vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
LoadingContainer.displayName = 'LoadingContainer';

/* circular, rotating logo of W */
const Logo = styled.img`
  margin-bottom: 5rem;
  height: 16vmin;
  align-self: center;
`;
Logo.displayName = 'Logo';

/* contains post column in center of page */
const GiftContainer = styled.div`
  background-color: #FFFFFF;
  margin: 16px;
  border: 2px solid #FFD54F;
  padding: 16px 8px 16px 8px;
  border-radius: 25px;
  max-width: 320px;
  @media(max-width: 31.25rem) {
    min-width: 19rem;
  }
  @media(max-width: 28.25rem) {
    width: 80%;
  }
`;
GiftContainer.displayName = 'GiftContainer';

/* contains post author facebook pic and name, and action button */
const GiftHeader = styled.div`
  margin: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  
`;
GiftHeader.displayName = 'GiftHeader';

/* contains post author pic and name, and created date */
const GiftOwnerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  vertical-align: middle;
`;
GiftOwnerContainer.displayName = 'GiftOwnerContainer';

/* crop post author facebook pic to make it circular */
const GiftOwnerAvatarContainer = styled.div`
  border-radius: 50%;
  border: 2px solid #FFF8E1;
  box-shadow: 0 0 0 2pt #01579B;
  background-color: #FFF8E1;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  @media(max-width: 28.25rem) {
    width: 50px;
    height: 50px;

  }
`;
GiftOwnerAvatarContainer.displayName = 'GiftOwnerAvatarContainer';


/* post author facebook pic */
const GiftOwnerAvatar = styled.img`
  display: inline;
  height: 50px;
  width: 50px;
  object-fit: cover;
  image-resolution: 300dpi;

`;
GiftOwnerAvatar.displayName = 'GiftOwnerAvatar';

/* contains post author name and created date */
const GiftOwnerNameAndDateContainer = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
`;
GiftOwnerNameAndDateContainer.displayName = 'GiftOwnerNameAndDateContainer';

/* post author name */
const GiftOwnerName = styled.div`
  display: flex;
  padding:
  text-align: center;
  color: #100F0D;
  font-size: 1.2rem;
  @media(max-width: 28.25rem) {
    max-width: 9rem;
  }
`;
GiftOwnerName.displayName = 'GiftOwnerName';

/* contains clock icon and post created date */
const GiftCreatedDateContainer = styled.div`
  margin-top: 0.15rem;
  display: flex;
  flex-flow: row nowrap;
`;
GiftCreatedDateContainer.displayName = 'GiftCreatedDateContainer';

/* clock icon next to post created date */
const ClockIconContainer = styled.div`
  margin-right: 0.25rem;
  display: flex;
  height: 0.7rem;
  width: 0.7rem;
  align-self: center;
`;
ClockIconContainer.displayName = 'ClockIconContainer';

const ClockIcon = styled.img`
  height: 0.7rem;
  width: 0.7rem;
`;
ClockIcon.displayName = 'ClockIcon';

/* post created date - time ago format */
const GiftCreatedDate = styled(Moment)`
  font-size: 0.8rem;
  color: #777777;
`;
GiftCreatedDate.displayName = 'GiftCreatedDate';

/* contains clock icon and post created date */
const SparkleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
`;
SparkleContainer.displayName = 'SparkleContainer';


const SparkleIcon = styled.img`
  margin-left: .25rem;
  height: 1rem;
  width: 1rem;
`;
SparkleIcon.displayName = 'SparkleIcon';

/* post author name */
const SparkleAmount = styled.div`
  display: flex;
  text-align: right;
  align-self: center;
  color: #100F0D;
  font-size: 1.25rem;
  letter-spacing: -0.05em;
  @media(max-width: 28.25rem) {
    max-width: 9rem;
  }
`;
SparkleAmount.displayName = 'SparkleAmount';

/* action button above post image */
const SeeItInTheAppButton = styled.button`
  border: none;
  background-color: #F7F7F7;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
SeeItInTheAppButton.displayName = 'SeeItInTheAppButton';

/* clock icon next to post created date */
const ExpandMoreIconContainer = styled.div`
  display: flex;
  height: 1.75rem;
  width: 2.25rem;
  align-items: center;
`;
ExpandMoreIconContainer.displayName = 'ExpandMoreIconContainer';

const ExpandMoreIcon = styled.img`
  height: 1.75rem;
  width: 2.25rem;
`;
ExpandMoreIcon.displayName = 'ExpandMoreIcon';

/* contains share button */
const GiftShareContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
GiftShareContainer.displayName = 'GiftShareContainer';

/* share button icon */
const GiftShareButton = styled.button`
  border: none;
  border-radius: 25px;
  padding: 0 1rem;
  margin-top: 1rem;
  align-self: right;
  min-width: 8rem;
  max-width: 24rem;
  width: 88%;
  height: 2.5rem;
  background-color: #01579B;
  color: #FFF8E1;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 5px 15px rgba(0, 105, 92, .4);
  :focus {
    outline: none;
  }
  :hover {
    
  }
  @media(max-width: 28.25rem) {
    height: 2.5rem;
    font-size: 1rem;
  }
`;
GiftShareButton.displayName = 'GiftShareButton';

/* number of likes and views below post image and above post title */
const GiftLikesAndViews = styled.div`
  padding-top: 0.2rem;
  text-align: left;
  font-weight: 600;
  font-size: 0.85rem;
  color: #535353;
`;
GiftLikesAndViews.displayName = 'GiftLikesAndViews';

/* title of post below image */
const GiftTitle = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
`;
GiftTitle.displayName = 'GiftTitle';

/* description of post below title */
const GiftDescription = styled.div`
  margin: .5rem 1rem;
  padding: 10px 5px 5px 5px;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #424242;
  white-space: pre-line;
`;
GiftDescription.displayName = 'GiftDescription';

/* description of post below title */
const InterestDescription = styled.div`
  margin-top: 2.0rem;
  padding: 2rem;
  text-align: center;
  background-color: #F7F7F7;
  font-size: 1.0rem;
  color: #333333;
  white-space: pre-line;
`;
InterestDescription.displayName = 'InterestDescription';

/* "post not found" */
const ErrorText = styled.div`
  font-size: 1.2rem;
`;
ErrorText.displayName = 'ErrorText';

/* contains all comments, below description */
const GiftComments = styled.div`
  text-align: left;
`;
GiftComments.displayName = 'GiftComments';

/* single comment */
const GiftComment = styled.div`
  margin: 0.4rem 1rem;
  display: block;
`;
GiftComment.displayName = 'GiftComment';

/* author of comment */
const GiftCommentAuthor = styled.div`
  margin-right: 0.6rem;
  display: inline-block;
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
`;
GiftCommentAuthor.displayName = 'GiftCommentAuthor';

/* content of comment */
const GiftCommentContent = styled.div`
  margin-right: 0.6rem;
  display: inline-block;
  text-align: left;
  font-size: 0.8rem;
`;
GiftCommentContent.displayName = 'GiftCommentContent';

/* shows message when user copies url to clipboard */
const CopySuccess = styled.div`
  margin: -1.25rem 0 0 -6.25rem;
  border-radius: 4px;
  padding: 0.375rem 0.375rem 0.375rem 0.85rem;
  position: absolute;
  display: ${p => p.showSuccess ? "block" : "none"};
  top: 50%;
  left: 50%;
  width: 11.25rem;
  background: #24263D;
  background: ${p => p.showSuccess ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0)"};
  font-size: 0.8125rem;
  color: #FFFFFF;
  content: attr(title);
  z-index: 99;
`;
CopySuccess.displayName = 'CopySuccess';

class ShareGift extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      message: '',
      images: [],
      userPhotoUrl: null,
      userDisplayName: '',
      actionButtonText: '',
      showCopySuccess: false,
      backgroundColor: '#E8F5E9' 
    }
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

  /* get the action button text according to post type */
  getActionButtonText = (post) => {
    return "Save Card in App";
  }

  // openAndroidAppUrl = () => {
  //   window.open("https://play.google.com/store/apps/details?id=com.mana.giftbank", "_blank")
  // }

  // openiOSAppUrl = () => {
  //   window.open("https://apps.apple.com/us/app/id1525779323", "_blank")
  // }


  /* copy url to clipboard */
  copyUrlToClipboard = () => {
    const url = window.location.href;
    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      textArea.style.position = "none";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.showCopySuccessTooltip();
      } catch (_) {
        /* did not copy successfully */
      }

      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(url).then(() => {
      /* successfully copied */
      this.showCopySuccessTooltip();
    }, (() => {
      /* did not copy successfully */
    }));
  }

  /* briefly show 'Copied URL to clipboard✨' message */
  showCopySuccessTooltip = () => {
    this.setState({ showCopySuccess: true });
    this.hideCopySuccessTooltip();
  }

  /* hide 'Copied URL to clipboard✨' message after 1.6 seconds */
  hideCopySuccessTooltip = () => {
    setTimeout(() => {
      this.setState({ showCopySuccess: false });
    }, 1600);
  }

  /* change current image index */
  changeImageIndex = (direction) => {
    const newIndex = this.state.imageIndex + direction;
    if (newIndex < 0 || newIndex >= this.state.images.length) {
      return;
    }
    /* new index is valid, change state */
    this.setState({
      imageIndex: newIndex,
    });
  }

  async componentDidMount() {
    /* get post ID from url */
    const giftID = this.props.match.params.giftID;
    const gift = await getGift(giftID);
    if (gift) {
      /* format created date */
      const seconds = gift.createdAt._seconds;
      const createdDate = new Date(0);
      createdDate.setUTCSeconds(seconds);
      /* set text of action button based on post type */
      const actionButtonText = this.getActionButtonText(gift);
      this.setState({
        error: false,
        message: gift.note,
        amount: gift.amount,
        createdDate: createdDate,
        userPhotoUrl: 'https://firebasestorage.googleapis.com/v0/b/mana-api-prod.appspot.com/o/profile%2F772a124f-10ac-4934-bbf5-227c742be426?alt=media&token=862ae68a-588b-4760-9a54-998d1e14905c',
        userDisplayName: gift.gifterName,
        actionButtonText: 'Claim',
        shareUrl: `https://manaverse.studio`|| undefined,
        isExpanded: false
      });
    } else {
      /* show invalid post id error */
      this.setState({
        loading: false,
        error: true,
      });
    }
  }

  render() {
    const {
      loading,
      error,
      message,
      amount,
      createdDate,
      userPhotoUrl,
      userDisplayName,
      shareUrl,
      showCopySuccess,
      isExpanded,
      isPaused
    } = this.state;

    /* show loading spinner if post has not yet been loaded */
    if (loading) {
      return (
        <LoadingPageWrapper>
          <LoadingBodyContainer>
            <LoadingContainer>
            <Lottie options={defaultOptions}
              height={'100vmin'}
              width={'100vmin'}
              eventListeners={
                [{
                  eventName: 'complete',
                  callback: () => this.setState({loading: false}),
                }]
              }/>
            </LoadingContainer>
          </LoadingBodyContainer>
        </LoadingPageWrapper>
      );
    }

    /* show error when no post is found for this post ID */
    if (error) {
      console.log(error)
      return (
        <PageWrapper>
          <PageHeader>
            <Wordmark src={wordmark} alt="" />
          </PageHeader>
          <BodyContainer>
            <ErrorText>
              Gift not found.
            </ErrorText>
          </BodyContainer>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <BodyContainer>
          <GiftContainer onClick={() => this.setState({ isExpanded: !isExpanded })}>
            <GiftHeader>
              <GiftOwnerContainer>
                <GiftOwnerAvatarContainer>
                    <GiftOwnerAvatar src={userPhotoUrl} />
                </GiftOwnerAvatarContainer>
                <GiftOwnerNameAndDateContainer>
                  <GiftOwnerName>{userDisplayName}</GiftOwnerName>
                  <GiftCreatedDateContainer>
                    <ClockIconContainer>
                      <ClockIcon src={calendarIcon} />
                    </ClockIconContainer>
                    <GiftCreatedDate format='MMM D, Y'>
                      {createdDate}
                    </GiftCreatedDate>
                  </GiftCreatedDateContainer>
                </GiftOwnerNameAndDateContainer>
              </GiftOwnerContainer>
              <SparkleContainer>
                <SparkleAmount>${amount}</SparkleAmount>
              </SparkleContainer>
            </GiftHeader>
            <GiftDescription>{message}</GiftDescription>
            <GiftShareContainer>
            <GiftShareButton onClick={() => window.open(shareUrl)}>
                    {this.state.actionButtonText}
                </GiftShareButton>
            </GiftShareContainer> 
          </GiftContainer>
        </BodyContainer>
      </PageWrapper>
    );
  }
}

export default ShareGift;